.container {
  top: 5rem;
  width: 25rem;
  left: -21rem;
  padding: 1.2rem;
  position: absolute;
  border-radius: 0.8rem;
  background-color: #7775fc;
}

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;

  &::before {
    content: "";
    right: 0;
    top: -1.7rem;
    position: absolute;
    transform: translate(-50%);
    border-bottom: 0.5rem solid #7775fc;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }

  p {
    color: var(--color-white);
  }
}

.action__btn {
  cursor: pointer;
  font-weight: 500;
  font-family: inherit;
  margin: 0.5rem 0 0 auto;
  color: var(--color-white);
}
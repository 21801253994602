.container {
  display: flex;
  padding: 0 3rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 26em) {
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 0 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--text-color);
  }
}

.controls {
  gap: 2rem;
  display: flex;

  button {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    align-items: center;
    font-family: inherit;
    justify-content: center;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    transition: all 0.25s ease-in;

    &.disabled {
      cursor: not-allowed;
      background-color: #f9f9f9;

      svg {
        opacity: 0.5;
      }
    }

    &.active {
      color: #ffffff;
      background-color: #93a3c361;
    }

    &:hover {
      color: var(--color-dark-blue);
      background-color: #93a3c361;
    }
  }
}

.container {
  display: flex;
  padding: 2rem 0;
  align-items: center;
  margin-bottom: 1.2rem;

  @media screen and (max-width: 66em) {
    padding: 1rem 0;
  }

  @media screen and (max-width: 26.6em) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.heading {
  flex: 1;
  display: block;

  h2 {
    font-weight: 700;
    font-size: 2.8rem;
  }
}

.menu {
  gap: 0.9rem;
  display: flex;
  position: relative;
  align-items: center;

  @media screen and (max-width: 26.6em) {
    align-self: flex-end;
  }

  button {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
    align-items: center;
    border-radius: 0.8rem;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  }
}

.btn__menu {
  background-color: var(--color-white);
}

.btn__logOut {
  background-color: var(--color-red);
}

.container {
  display: flex;
  padding: 0 3rem;
  flex-direction: column;

  @media screen and (max-width: 26.6em) {
    padding: 0;
  }
}

.content {
  display: grid;
  padding: 2rem;
  margin-top: 3rem;
  gap: 3rem 2rem;
  border-radius: 0.8rem;
  border: 1px solid #f2f2f2;
  grid-template-rows: min-content;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 90em) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 26.6em) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

.textGroup {
  gap: 0.6rem;
  display: flex;

  span {
    font-weight: 500;
    white-space: nowrap;
    color: var(--color-dark-blue);

    @media screen and (max-width: 26.6em) {
      white-space: initial;
    }
  }

  p {
    color: var(--text-color);
  }
}

.group {
  padding: 2rem;
  display: grid;
  grid-column: 1 / -1;
  border-radius: 0.4rem;
  border: 1px solid #f2f2f2;
  grid-template-rows: min-content;
  grid-template-columns: repeat(3, 1fr);

  legend {
    font-weight: 500;
    font-size: 1.5rem;
    white-space: nowrap;
    color: var(--color-dark-blue);
  }
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("/assets/fonts/KumbhSans-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("/assets/fonts/KumbhSans-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("/assets/fonts/KumbhSans-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Kumbh Sans";
  src: url("/assets/fonts/KumbhSans-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

:root {
  --font-primary: "Kumbh Sans", sans-serif;

  --bg-color: #f7f9fb;
  --color-red: #eb1d36;
  --text-color: #67748e;
  --color-gray: #c7c3c3;
  --color-green: #2dad6a;
  --color-white: #ffffff;
  --border-color: #e7e7e7;
  --hover-bg-color: #f5f5f5;
  --color-dark-blue: #344767;
  --bg-transparent: transparent;
  --color-blue: #2f58cd;
  --color-dark-gray: #333333;

  --section-padding: 4rem;

  @media screen and (max-width: 66em) {
    --section-padding: 3rem 2rem;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 68.8%; // 1rem = 11px
}

body {
  font-size: 1.4rem;
  font-family: var(--font-primary);
  background-color: var(--bg-color);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

input,
select,
textarea {
  width: 100%;
  outline: none;
  background: none;
  appearance: none;
  line-height: 1.1;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

select {
  cursor: pointer;
}

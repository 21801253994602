.container {
  margin-top: 1rem;
  overflow-x: auto;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    height: 1.1rem;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    background-color: #cccccc;
    border: 3px solid #cccccc;
  }

  @media screen and (max-width: 66em) {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
  }
}

table {
  width: 100%;
  font-family: inherit;
  margin: 4rem 0 2.5rem;
  border-collapse: collapse;

  thead tr {
    text-align: left;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
  }

  tbody tr {
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border: none;
    }
  }

  th {
    font-size: 1.3rem;
    white-space: nowrap;
    text-transform: capitalize;
  }

  td {
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    color: var(--text-color);
  }

  th,
  td {
    padding: 1.6rem 2rem;
  }
}

.actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  button {
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
  }
}

.wrapper {
    font-size: 1.37rem;
    font-weight: 400;
    padding-left: 2.5rem;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        width: 0;
        height: 0;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        -webkit-user-select: text;
        user-select: text;
    }

    span {
        top: 0;
        left: 0;
        height: 1.8rem;
        width: 1.8rem;
        position: absolute;
        border-radius: 0.2rem;
        border: 1px solid #939598;
        background-color: transparent;

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 0.5rem;
            top: 0.1rem;
            width: 7px;
            height: 11px;
            border: solid #ffffff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover input + span {
        background-color: #0071f30e;
    }

    & > input:checked + span {
        background-color: var(--color-blue);
        border: 1px solid var(--color-blue);
    }

    & > input:checked + span::after {
        display: block;
    }
}

.container {
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  position: fixed;
  min-height: 100vh;
  background-color: var(--color-white);
}

.header {
  gap: 1.4rem;
  display: flex;
  align-items: center;
  padding: 2rem 1.7rem;
  border-bottom: 1px solid var(--border-color);

  button {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 600;
    font-size: 1.8rem;
    color: var(--color-dark-blue);
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 66em) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &__menu {
    display: flex;
    margin-top: 5.5rem;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    padding: 0 4rem;
    max-width: 55rem;
    margin-top: 3rem;
    flex-direction: column;
  }
}

.title {
  margin: 0 auto;

  @media screen and (max-width: 66em) {
    margin: 0;
  }

  h2 {
    font-size: 2.45rem;
    color: var(--color-dark-blue);
  }
}

.options {
  margin-top: 5rem;

  @media screen and (max-width: 66em) {
    margin-top: 3rem;
  }
}

.grid {
  margin: 0 auto;
  display: grid;
  gap: 1.6rem;
  max-width: 81.8rem;
  grid-template-rows: min-content;
  grid-template-columns: repeat(2, minmax(20rem, 1fr));

  @media screen and (max-width: 66em) {
    grid-template-columns: 1fr;
  }

  &__item {
    gap: 1rem;
    display: flex;
    padding: 2.4rem 1.9rem;
    border-radius: 0.8rem;
    border: 1px solid var(--border-color);

    &.active {
      border: 1px solid var(--color-blue);
    }
  }
}

.item {
  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 1.6rem;
      margin-bottom: 1.1rem;
      color: var(--color-dark-blue);
    }

    p {
      font-size: 1.5rem;
      color: var(--text-color);
    }
  }

  &__marker .circle {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    border: 1px solid var(--border-color);
  }
}

.action {
  margin: 6rem auto 0;
}

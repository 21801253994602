.container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.wrapper {
    width: 50%;
    gap: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 27em) {
        width: 100%;
        padding: 0 1.5rem;
    }

    h1 {
        font-size: 3rem;
    }

    p {
        font-weight: 500;
        font-size: 1.7rem;
        text-align: center;

        &:last-of-type {
            font-size: 1.6rem;
            color: var(--color-gray);
        }
    }

    button {
        gap: 0.3rem;
        display: flex;
        align-items: center;
        font-family: var(--font-primary);
    }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  padding-left: 3rem;
  padding-right: 3rem;
  align-items: center;

  @media screen and (max-width: 26.6em) {
    gap: 2rem;
    padding: 0;
    flex-direction: column;
  }

  > h3 {
    flex: 1;
    font-weight: 400;
    font-size: 1.6rem;
    color: var(--text-color);

    + div {
      h3 {
        font-weight: 600;
        margin-right: 0.7rem;
        color: var(--color-dark-blue);
      }
    }
  }

  &Options {
    gap: 2rem;
    display: flex;

    @media screen and (max-width: 26.6em) {
      margin-left: auto;
    }
  }
}

.customSelect {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.3rem 0.6rem;
  border: 1px solid var(--border-color);

  select {
    border: none;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 1.28rem;
    font-family: inherit;
    color: var(--text-color);
  }
}

.miniCards {
  gap: 2.5rem;
  display: grid;
  padding: 0 3rem;
  grid-template-rows: min-content;
  grid-template-columns: 1fr repeat(2, minmax(min-content, 15rem));

  @media screen and (max-width: 48em) {
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 26.6em) {
    padding: 0;
    grid-template-columns: 1fr;
  }

  & > *:first-child {
    justify-self: flex-start;
    width: 15rem;

    @media screen and (max-width: 26.6em) {
      width: 100%;
    }
  }
}

.miniCardItem {
  display: flex;
  border-radius: 0.8rem;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 6px 15px;
}

.miniCardHeading {
  padding: 0.8rem 0;
  background-color: #f4f4f4;
  border-radius: 0.8rem 0.8rem 0 0;

  h4 {
    font-weight: 600;
    text-align: center;
    color: var(--color-dark-blue);
  }
}

.miniCardBody {
  padding: 0.6rem 0;

  p {
    font-weight: 500;
    text-align: center;
    color: var(--text-color);
  }
}

.container {
  flex: 1;
  margin-right: 2rem;

  @media screen and (max-width: 26.6em) {
    width: 100%;
    margin: 1.5rem 0 1.5rem;
  }
}

.input {
  gap: 0.5rem;
  display: grid;
  border-radius: 1rem;
  padding: 1rem 1.4rem;
  grid-template-rows: min-content;
  border: 2px solid var(--border-color);
  grid-template-columns: 1fr min-content;

  input {
    line-height: 1.5;
    background: none;
    font-size: 1.4rem;
    font-family: inherit;
    border: 0 !important;
    transition: all 0.24s ease-in;

    &:focus {
      border: 2px solid #c2c2c2;
    }

    &::-webkit-input-placeholder {
      color: var(--color-gray);
    }
  }
}

.container {
  margin: 0 4rem;
  overflow-y: auto;
  max-height: 85vh;
  padding-top: 3rem;
  position: relative;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    background-color: #cccccc;
    border: 3px solid #cccccc;
  }

  @media screen and (max-width: 48em) {
    margin: 0 2rem;
  }

  @media screen and (max-width: 26.6em) {
    margin: 0 1.5rem;
  }
}

.modalBtn {
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);

  &:focus-within {
    border: 1px solid var(--color-dark-blue);
  }
}

.container {
    margin-top: 1rem;
    overflow-x: auto;
    padding-bottom: 1rem;

    &::-webkit-scrollbar {
        height: 1.1rem;
    }

    &::-webkit-scrollbar-track {
        background: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.6rem;
        background-color: #cccccc;
        border: 3px solid #cccccc;
    }

    @media screen and (max-width: 66em) {
        box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
    }
}

.container {
  display: grid;
  gap: 2rem 1.7rem;
  grid-template-rows: min-content;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 26.6em) {
    grid-template-columns: 1fr;
  }
}

.wrapper {
  width: 70%;
  display: block;
  margin-top: 3rem;

  @media screen and (max-width: 66em) {
    width: 80%;
  }

  @media screen and (max-width: 27em) {
    width: 100%;
  }
}

.group {
  display: flex;
  position: relative;
  flex-direction: column;
}

.label {
  font-weight: 600;
  font-size: 1.35rem;
  margin-bottom: 0.7rem;
  color: var(--color-dark-blue);
}

.input,
.select,
.textarea {
  line-height: 1.5;
  font-size: 1.4rem;
  border-radius: 1rem;
  font-family: inherit;
  padding: 1.4rem 1.6rem;
  transition: all 0.24s ease-in;
  border: 2px solid var(--border-color);

  &:focus {
    border: 2px solid #c2c2c2;
  }

  &::-webkit-input-placeholder {
    color: var(--color-gray);
  }
}

.textarea {
  resize: none;
}

.btn {
  grid-column: 1 / -1;

  button {
    color: var(--color-white);
    font-weight: 500;
    font-size: 1.45rem;
    border-radius: 1rem;
    font-family: inherit;
    padding: 0.9rem 1.6rem;
    transition: all 0.25s ease;
    background-color: var(--color-blue);

    &:disabled {
      cursor: not-allowed;
      color: var(--color-white);
      background-color: var(--color-gray);
    }
  }
}

.btnRed {
  background-color: var(--color-red) !important;
}

.legend {
  gap: 2.2rem;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  h3 {
    font-weight: 600;
    font-size: 1.45rem;
    grid-column: 1 / -1;
    border-radius: 0.8rem;
    padding: 0.9rem 1.4rem;
    color: var(--color-white);
    background-color: #4b5d67;
    box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  }
}

.textError {
  margin-top: 0.7rem;
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--color-red);
}

.labelError {
  color: var(--color-red);
}

.inputError,
.selectError,
.textareaError {
  border: 2px solid var(--color-red);

  &:focus {
    border: 2px solid var(--color-red);
  }
}

.enter {
    max-height: 0;
    overflow: hidden;
}

.enterActive {
    overflow: visible;
    max-height: 500rem;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.exit {
    overflow: visible;
    max-height: 500rem;
}

.exitActive {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.wFull {
  width: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.itemsCenter {
  align-items: center;
}

.justifyStart {
  justify-content: flex-start !important;
}

.justifyCenter {
  justify-content: center;
}

.column100 {
  grid-column: 1 / -1;
}

.alignColEnd {
  margin-top: auto;
}

.alignRowStart {
  align-self: start;
}

.gap10 {
  gap: 1rem;
}

.gap15 {
  gap: 1.5rem;
}

.gap0 {
  gap: 0;
}

.grid {
  display: grid;

  &_col1__min_content {
    grid-template-rows: min-content;
    grid-template-columns: 1fr min-content;

    @media screen and (max-width: 26.6em) {
      & > *:first-child {
        grid-column: 1 / -1;
      }
    }
  }

  &_col2 {
    grid-template-rows: min-content;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 26.6em) {
      grid-template-columns: 1fr;
    }
  }

  &_col3 {
    grid-template-rows: min-content;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 26.6em) {
      grid-template-columns: 1fr;
    }
  }
}

.m_0 {
  margin: 0 !important;
}

.noDisplay {
  display: none;
}

.ml_1 {
  margin-left: 1rem;
}

.outline__button {
  gap: 0.6rem;
  display: flex;
  font-size: 1.4rem;
  position: relative;
  align-items: center;
  font-family: inherit;
  color: var(--color-dark-blue);

  &:focus-within {
    outline: auto;
  }
}

.select__dropdown {
  top: 2.5rem;
  z-index: 50;
  width: 14rem;
  display: flex;
  left: -4.5rem;
  padding: 1rem 0;
  position: absolute;
  border-radius: 0.5rem;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  span {
    text-align: left;
    padding: 0.8rem 1.4rem;
    transition: all 0.24s ease;

    &:hover {
      background-color: #f4f5f6;
    }
  }
}

.textWarn {
  display: flex;
  font-weight: 500;
  align-items: center;
  color: rgb(215, 172, 16);
}

.container {
  padding: 0 3rem;
  border-radius: 0.8rem;
}

.tabs {
  display: flex;

  &__item {
    &.active button {
      border-radius: 0.8rem 0.8rem 0 0;
      background-color: var(--color-white);
    }

    button {
        font-size: 1.4rem;
        font-family: inherit;
        padding: 0.8em 1.2em;
      color: var(--text-color);
    }
  }
}

.content {
    padding: 2rem 3rem;
    border-radius: 0.8rem;
    background-color: var(--color-white);
}

.container {
    left: 0;
    width: 100%;
    z-index: 50;
    top: 8.5rem;
    overflow-y: auto;
    max-height: 25rem;
    position: absolute;
    border-radius: 0.8rem;
    background-color: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
        background: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0.6rem;
        background-color: #cccccc;
        border: 3px solid #cccccc;
    }
}

.list {
    list-style: none;

    li {
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--color-dark-gray);
        border-bottom: 1px solid var(--border-color);

        button {
            width: 100%;
            color: inherit;
            cursor: pointer;
            text-align: left;
            font-size: inherit;
            font-family: inherit;
            padding: 1.3rem 1.6rem;
            background-color: transparent;
            transition: all 0.4s ease;

            &:hover:enabled {
                background-color: var(--hover-bg-color);
            }
        }
    }
}

.container {
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  display: flex;
  position: fixed;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.container {
    display: flex;
}

.content {
    flex: 1;
    padding: 1.5rem 2rem;
    transform: translateX(28rem);
    max-width: calc(100% - 28rem);
    transition: max-width 200ms ease-in-out, transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    @media screen and (max-width: 66em) {
        max-width: 100%;
        padding: 1.5rem;
        min-height: 100vh;
        transform: translateX(0);
        background-color: var(--bg-color);
    }

    &__wrapper {
        z-index: 20;
        position: relative;
    }

    &__card {
        padding: 2rem 0;
        border-radius: 1rem;
        background-color: var(--color-white);
        box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        @media screen and (max-width: 26.6em) {
            padding: 2rem 1.5rem;
        }
    }

    &__empty {
        display: flex;
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        p {
            font-size: 1.4rem;
            color: var(--color-gray);
        }
    }
}

.translateX_left {
    max-width: 100%;
    transform: translateX(0);
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  display: flex;
  position: fixed;
  overflow-y: auto;
  padding: 4rem 4rem 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
}

.positionTop {
  padding-top: 4rem !important;
}

.positionCenter {
  padding-top: 11rem !important;
}

.container {
  width: 100%;
  border-radius: 0.7rem;
  align-self: flex-start;
  background-color: var(--color-white);
  box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
}

.header {
  display: flex;
  align-items: center;
  padding: 1.4rem 2.2rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);

  h2 {
    font-weight: 700;
    font-size: 1.8rem;
  }
}

.content {
  margin: 1rem 0;
  padding: 0.5rem 2.2rem;

  p {
    font-weight: 500;
    font-size: 1.6rem;
  }
}

.actions {
  gap: 1.2rem;
  display: flex;
  margin-top: 1.3rem;
  justify-content: center;
  padding: 1.2rem 0 1.5rem;

  button {
    font-weight: 500;
    font-size: 1.45rem;
    border-radius: 1rem;
    font-family: inherit;
    padding: 0.9rem 1.6rem;
    color: var(--color-white);

    &:disabled {
      cursor: not-allowed;
      color: var(--color-white);
      background-color: var(--color-gray);
    }
  }
}

.wrapper {
  margin: 1.7rem 0 0;
}

.btnClose {
  background-color: var(--color-red);
}

.btnConfirm {
  background-color: var(--color-green);
}

.animFadeInTop {
  animation: fadeInTop 0.5s ease-out forwards;
}

.animFadeOutTop {
  animation: fadeOutTop 0.3s ease-out forwards;
}

.scaleIn {
  animation: scaleIn 0.3s ease-out forwards;
}

.scaleOut {
  animation: scaleOut 0.3s ease-out forwards;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    opacity: 0.8;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

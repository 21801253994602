.overlay {
  display: block;
}

.visible {
  width: auto;

  @media screen and (max-width: 66em) {
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    position: fixed;
  }
}

.blur {
  @media screen and (max-width: 66em) {
    display: block;

    @supports (-webkit-backdrop-filter: blur(10px)) or
      (backdrop-filter: blur(10px)) {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.btnClose {
  top: 3rem;
  right: 1rem;
  position: absolute;

  @media screen and (min-width: 66em) {
    display: none;
  }

  button {
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.8rem;
    justify-content: center;
    background: var(--color-white);
  }
}

.container {
  top: 0;
  left: 0;
  z-index: 80;
  width: 28rem;
  height: 100vh;
  display: flex;
  position: fixed;
  max-height: 100vh;
  padding: 4rem 2.8rem 0 2.8rem;
  background-color: var(--bg-color);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  @media screen and (max-width: 66em) {
    padding: 3rem 2rem 0 2rem;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    background-color: #cccccc;
    border: 3px solid #cccccc;
  }

  @media screen and (max-width: 66em) {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
  }
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.list,
.sublist {
  list-style: none;

  &_item {
    display: flex;
    flex-direction: column;

    a,
    button {
      flex: 1;
      display: flex;
      padding: 1.1rem 0;
      align-items: center;

      p {
        flex: 1;
        font-weight: 400;
        font-size: 1.4rem;
        text-align: left;
        margin-left: 0.9rem;
        color: var(--text-color);
        font-family: var(--font-primary);
      }

      span {
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        align-items: center;
        justify-content: center;

        &.rounded {
          border-radius: 0.8rem;
          background-color: var(--color-white);
          box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
        }

        &.bg_transparent {
          box-shadow: none;
          background-color: var(--bg-transparent);
        }

        &:last-child {
          svg {
            transition: transform 250ms ease;
          }
        }

        &.rotate {
          svg {
            transform: rotate(180deg);
            fill: var(--color-dark-blue);
          }
        }
      }
    }
  }
}

.list {
  display: flex;
  overflow-y: auto;
  padding-right: 1.5rem;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    background-color: #cccccc;
    border: 3px solid #cccccc;
  }
}

.sublist {
  &_item {
    a {
      padding: 0.2rem 0;
    }
  }
}

.footer {
  display: flex;

  button {
    flex: 1;
    display: flex;
    align-items: center;
    font-family: inherit;
    padding: 2.5rem 1.4rem;

    span {
      flex: 1;
      text-align: left;
      font-size: 1.4rem;
      margin-left: 0.9rem;
      color: var(--text-color);
    }
  }
}

.open {
  animation: sideBarOpen 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms forwards;
}

.close {
  animation: sideBarClose 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms forwards;
}

@keyframes sideBarOpen {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0) !important;
  }
}

@keyframes sideBarClose {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.overlay {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    height: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    transition: all 0.25s ease-in;

    @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.1);
    }

    @media screen and (max-width: 27em) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.panel {
    margin-top: 8rem;
    border-radius: 1rem;
    align-self: flex-start;
    padding: 1rem 0 2.2rem;
    background-color: #ffffff;
    box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.brand {
    padding: 2rem 8rem;

    > div {
        margin-bottom: 0;
    }
}

.content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    h3 {
        font-weight: 600;
        font-size: 1.7rem;
        color: var(--text-color);
    }
}

.form {
    margin-top: 2.1rem;

    form {
        grid-template-columns: 1fr;
    }
}

.feedback {
    border-radius: 0.8rem;
    padding: 1.4rem 1.8rem;
    color: var(--color-white);
    margin: 0 1.5rem 1.5rem 1.5rem;
    border-top: 1px solid var(--color-red);
    border-bottom: 1px solid var(--color-red);
    background-color: rgba(255, 0, 0, 0.772);

    p {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
    }
}

.scaleIn {
    animation: scaleIn 0.3s ease-out forwards;
}

.scaleOut {
    animation: scaleOut 0.3s ease-out forwards;
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes scaleOut {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

.fieldset {
  padding: 0 1.4rem 1.4rem;
}

.legendTitle {
  padding: 1.2rem;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--color-dark-blue);
}

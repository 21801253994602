.container {
    display: flex;
    align-self: center;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid var(--border-color);

    h1 {
        font-weight: 600;
        font-size: 1.6rem;
        margin-left: 0.6rem;
        color: var(--color-dark-blue);
    }
}
